import { render, staticRenderFns } from "./LoginHeader.vue?vue&type=template&id=5d6e2e20&"
import script from "./LoginHeader.vue?vue&type=script&lang=js&"
export * from "./LoginHeader.vue?vue&type=script&lang=js&"
import style0 from "./LoginHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports