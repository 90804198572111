<template>
  <div class="content">
    <fade-transition :duration="100" mode="out-in">
      <!-- your content here -->
      <router-view></router-view>
    </fade-transition>
    <!-- <Footer /> -->
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';
import Footer from '@/components/Footer';
export default {
  components: {
    FadeTransition,
    Footer
  }
};
</script>

<style scoped>
.content{
  background-color: #f0f2f5;
}
</style>