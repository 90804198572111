<template>
  <div>
    <nav class="my-navbar">
      <div class="myLogo">
        <img class="logo" :src="MarcaNavegam" @click="rotas('')" />
      </div>

      <img :src="IconMenu" class="iconMenu" @click="openNav()" />
      <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" @click="closeNav()"
          >&times;</a
        >
        <a href="#" @click="rotas('')">Início</a>
        <a href="#" @click="rotas('perfil')">Meu Perfil</a>
        <a href="#" @click="rotas('meustickets')">Minhas Passagens</a>
        <a v-if="isLogged" @click="logout()">Logout</a>
        <a v-else @click="rotas('login')">Login</a>
      </div>
      <div class="button-team">
        <button
          v-if="isLogged"
          :class="[
            this.actualRoute == paginaDashboard ||
            this.actualRoute == paginaCompra ||
            this.actualRoute == paginaCheckout
              ? 'disabled'
              : ''
          ]"
          id="btn1"
          class="nav-button"
          @click="rotas('')"
          simple
        >
          <!-- <img class="icons" :src="IconPesquisar" /> -->
          <div class="buttonText actions">Início</div>
        </button>
        <button
          v-else
          style="position: absolute; margin-top: 0.3rem; left: 35%"
          :class="[
            this.actualRoute == paginaDashboard ||
            this.actualRoute == paginaCompra ||
            this.actualRoute == paginaCheckout
              ? 'disabled'
              : ''
          ]"
          class="nav-button"
          @click="rotas('')"
          simple
        >
          <!-- <img class="icons" :src="IconPesquisar" /> -->
          <div class="buttonText actions">Início</div>
        </button>

        <!-- <button
          v-if="isLogged"
          class="nav-button"
          :class="[ (this.actualRoute == paginaCompra) || this.actualRoute == paginaCheckout ? 'disabled' : '']"
          @click="rotas('purchase')"
          simple
          fill
        >
          <img class="icons" :src="IconCompra" /> 
          <div class="buttonText actions">Carrinho de compras</div>
        </button>-->

        <button
          v-if="isLogged"
          @click="rotas('meustickets')"
          :class="[this.actualRoute == paginaTickets ? 'disabled' : '']"
          style="position: absolute; margin-top: 0.3rem; left: 38%"
          class="nav-button"
          simple
          fill
        >
          <!-- <img class="icons" :src="IconTicket" /> -->
          <div class="buttonText actions">Minhas passagens</div>
        </button>

        <!-- <button class="nav-button" @click="openWppChat" simple fill>
          <img class="icons" :src="IconWhatsapp" style="width: 13px;" />
          <div class="buttonText actions">WhatsApp</div>
        </button>-->

        <button
          class="nav-button"
          style="position: absolute; margin-top: 0.3rem; right: 5px"
          :class="[
            this.actualRoute == paginaLogin ||
            this.actualRoute == paginaCadastro ||
            this.actualRoute == paginaEsqueciSenha
              ? 'disabled'
              : ''
          ]"
          v-if="!isLogged"
          @click="rotas('login')"
        >
          <!-- <i class="fas fa-sign-in-alt" style="font-size: 14px;" v-if="!isLogged"></i>  -->
          <div class="buttonText actions" v-if="!isLogged">Login</div>
        </button>
        <!-- <button
          class="dropdown nav-button dropbtn"
          :class="[ this.actualRoute == paginaLogin ? 'disabled' : '']"
          v-if="isLogged"
          @click="toggleDropDown"
        >
          <i class="fas fa-user dropbtn"></i>
          <div class="buttonText actions dropbtn">Ações</div>
        </button>-->
        <!-- <div id="myDropdown" class="dropdown-content">
          <span @click="rotas('alterarsenha')">Alterar Senha</span>
          <span @click="logout">Sair</span>
        </div>-->
        <LoginHeader v-if="isLogged" />
      </div>
    </nav>
  </div>
</template>

<script>
import Logo from "@/assets/img/navegam.svg";
import IconEntrar from "@/assets/icons/sign_in.svg";
import IconWhatsapp from "@/assets/icons/whatsapp.svg";
import IconPesquisar from "@/assets/icons/search.svg";
import IconTicket from "@/assets/icons/ticket.svg";
import IconMapa from "@/assets/icons/map_place.svg";
import IconSair from "@/assets/icons/signout.svg";
import IconCompra from "@/assets/icons/caminho/shoppingcart.svg";
import MarcaNavegam from "@/assets/MARCA_NAVEGAM.svg";
import LoginHeader from "./LoginHeader.vue";
import IconMenu from "@/assets/icons/menu.svg";
import IconWpp from "@/assets/icons/iconsWhatsapp.svg";

// import Sobre from '../assets/ic_about.svg';
// import Embarcacao from '../assets/ic_position.svg';
// import Cadastrar from '../assets/ic_singup.svg';

window.$ = window.jQuery = require("jquery");

export default {
  name: "navbar-main",
  components: {
    LoginHeader
  },
  data() {
    return {
      navegamNumber: "559284132558",
      navbarOpen: false,

      // Nomes das rotas (para o disabled nos botoes)
      paginaCadastro: "signup",
      paginaEsqueciSenha: "esquecisenha",
      paginaDashboard: "",
      paginaMapa: "mapa",
      paginaLogin: "login",
      paginaTickets: "meustickets",
      paginaCompra: "purchase",
      paginaCheckout: "checkout",

      // Logo Import
      Logo,

      // Image imports - Entrar : Entrar, etc
      IconEntrar,
      IconWhatsapp,
      IconPesquisar,
      IconMapa,
      IconTicket,
      IconSair,
      IconCompra,
      MarcaNavegam,
      IconMenu,
      IconWpp
    };
  },
  methods: {
    openNav() {
      document.getElementById("mySidenav").style.width = "50%";
      document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
      document.body.style.backgroundColor = "white";
    },
    openWppChat() {
      window.open("https://api.whatsapp.com/send?phone=" + this.navegamNumber);
    },
    rotas(rota) {
      this.$router.push(`/${rota}`);
      this.closeNav();
    },
    toggleNavbar() {
      this.navbarOpen = !this.navbarOpen;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleDropDown() {
      document.getElementById("myDropdown").classList.toggle("show");
    },
    logout() {
      this.$store.dispatch("logout");
      if (this.actualRoute != this.paginaPesquisar) {
        this.$router.push("/");
      }
      // document.location.reload(forceReload:true);
    }
  },
  computed: {
    actualRoute: function() {
      return this.$route.path.substr(1);
    },
    isLogged: function() {
      return this.$store.state.session.auth;
    }
  },
  mounted() {
    window.onclick = function(event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

// .button-team {
//   display: flex;
//   // align-content: center;
//   justify-content: space-between;
//   align-self: center;
//   flex-direction: row;
// }

.sidenav {
  height: 20%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #35415e;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 40px;
  filter: drop-shadow(2px 4px 6px black);
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 1em;
  color: white;
  font-family: "OpenSans-Regular", sans-serif;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover,
.offcanvas a:focus {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
/* Dropdown Button */
.dropbtn {
  float: right;
  padding-right: 0px;
  // background-color: transparent;
  // color: #344675;
  // padding: 13px;
  font-size: 16px;
  // font-weight: bold;
  // border: 1px solid #344675;
  // border-radius: 5px;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  margin-top: -2px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  // width: 7vh;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* Links inside the dropdown */
.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: fixed;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {
  background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  margin-top: 7.2vh;
  position: fixed;
  right: 12px;
  display: flex;
  font-family: "OpenSans-Regular", sans-serif;
  flex-direction: column;
  justify-items: flex-start;
  padding: 2px;
  border-radius: 3px;
}

.my-navbar {
  .iconMenu {
    cursor: pointer;
    display: none;
  }
  .btn-wpp {
    outline: none;
    display: none;
  }
  button {
    position: absolute;
    bottom: 0;
  }

  background-color: #35415e;
  position: fixed;
  padding-top: 10px;
  top: 0;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  z-index: 3;
  height: 7vh;
  max-height: 100px;
  box-shadow: 0px -2px 19px #000000aa;
  // @media only screen and (max-width: 800px) {
  //   display: flex;
  //   flex-direction: column;
  //   margin: 0 auto;
  //   height: 14vh;
  //   button {
  //     position: initial;
  //   }
  // }

  .myLogo {
    @media only screen and (max-width: 900px) {
      margin: 0 auto;
    }

    float: left;
    margin-left: 0.5rem;
  }

  .logo {
    height: 4vh;
    width: 120px;
    // float: left;
    margin-top: 8px;
    margin-left: 8px;
    // padding-bottom: 5px;
  }
  .logo:hover {
    cursor: pointer;
  }

  .button-team {
    // margin: 0 auto;
    display: flex;
    // justify-content: star;
    // float: right;
    // position: relative;
    vertical-align: middle;
    max-height: 100px;
    height: 100%;
    #btn1 {
      position: absolute;
      margin-top: 0.3rem;
      left: 25%;
    }
    .nav-button {
      border: none;
      background-color: transparent;
      height: 90%;
      max-height: 50px;
      // background-color: #00000000;
      // border: 1px solid #344675;
      // border-radius: 0.4285rem;
      // padding-top: ;
      padding-bottom: 0.8rem;
      // margin: auto;
      // font-weight: 600;
      text-align: center;
      vertical-align: middle;
      transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
      cursor: pointer;

      @media only screen and (max-width: 500px) {
        padding: 10px 15px;
      }

      .buttonText {
        // @media only screen and (max-width: 900px) {
        // display: none;
        // }

        color: white;
        font-size: 12px;
        font-family: "OpenSans-Regular", sans-serif;
        // font-weight: 600;
        vertical-align: middle;
      }
      // .icons {
      //   height: 15px;
      //   vertical-align: middle;
      //   filter: invert(21%) sepia(22%) saturate(4009%) hue-rotate(183deg)
      //     brightness(95%) contrast(90%);
      // }
    }

    .nav-button:not(.disabled):hover {
      transform: translate(0px, -2px);
      // box-shadow: 0px 2px 10px 0px #00000011;
      transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    }

    .nav-button:active {
      transform: translate(0px, 1px);
      transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    }

    .disabled {
      border-bottom: 3px solid #02e1db;
      cursor: default;
      .buttonText {
        color: #02e1db;
      }
    }

    .nav-button:active,
    .nav-button:focus {
      outline: none;
    }
  }
}

.buttonText {
  display: inline;
}

@media only screen and (max-width: 800px) {
  .my-navbar {
    height: 7vh;
    .button-team {
      display: none;
    }
    .myLogo {
      margin-left: 1rem;
    }
    .iconMenu {
      position: absolute;
      right: 1rem;
      top: 1.5rem;
      display: inline;
    }
    .btn-wpp {
      position: absolute;
      right: 6rem;
      display: inline;
      width: 2rem;
      bottom: 2px;
      background: transparent;
      border: none;
      color: white;
      font-family: "OpenSans-Regular", sans-serif;
      img {
        width: 25px;
        margin-left: 0.8rem;
      }
    }
    .btn-wpp:active {
      transform: translate(0px, 1px);
      transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    }
  }
}
</style>
