var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"my-navbar"},[_c('div',{staticClass:"myLogo"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.MarcaNavegam},on:{"click":function($event){return _vm.rotas('')}}})]),_c('img',{staticClass:"iconMenu",attrs:{"src":_vm.IconMenu},on:{"click":function($event){return _vm.openNav()}}}),_c('div',{staticClass:"sidenav",attrs:{"id":"mySidenav"}},[_c('a',{staticClass:"closebtn",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.closeNav()}}},[_vm._v("×")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.rotas('')}}},[_vm._v("Início")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.rotas('perfil')}}},[_vm._v("Meu Perfil")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.rotas('meustickets')}}},[_vm._v("Minhas Passagens")]),(_vm.isLogged)?_c('a',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")]):_c('a',{on:{"click":function($event){return _vm.rotas('login')}}},[_vm._v("Login")])]),_c('div',{staticClass:"button-team"},[(_vm.isLogged)?_c('button',{staticClass:"nav-button",class:[
          this.actualRoute == _vm.paginaDashboard ||
          this.actualRoute == _vm.paginaCompra ||
          this.actualRoute == _vm.paginaCheckout
            ? 'disabled'
            : ''
        ],attrs:{"id":"btn1","simple":""},on:{"click":function($event){return _vm.rotas('')}}},[_c('div',{staticClass:"buttonText actions"},[_vm._v("Início")])]):_c('button',{staticClass:"nav-button",class:[
          this.actualRoute == _vm.paginaDashboard ||
          this.actualRoute == _vm.paginaCompra ||
          this.actualRoute == _vm.paginaCheckout
            ? 'disabled'
            : ''
        ],staticStyle:{"position":"absolute","margin-top":"0.3rem","left":"35%"},attrs:{"simple":""},on:{"click":function($event){return _vm.rotas('')}}},[_c('div',{staticClass:"buttonText actions"},[_vm._v("Início")])]),(_vm.isLogged)?_c('button',{staticClass:"nav-button",class:[this.actualRoute == _vm.paginaTickets ? 'disabled' : ''],staticStyle:{"position":"absolute","margin-top":"0.3rem","left":"38%"},attrs:{"simple":"","fill":""},on:{"click":function($event){return _vm.rotas('meustickets')}}},[_c('div',{staticClass:"buttonText actions"},[_vm._v("Minhas passagens")])]):_vm._e(),(!_vm.isLogged)?_c('button',{staticClass:"nav-button",class:[
          this.actualRoute == _vm.paginaLogin ||
          this.actualRoute == _vm.paginaCadastro ||
          this.actualRoute == _vm.paginaEsqueciSenha
            ? 'disabled'
            : ''
        ],staticStyle:{"position":"absolute","margin-top":"0.3rem","right":"5px"},on:{"click":function($event){return _vm.rotas('login')}}},[(!_vm.isLogged)?_c('div',{staticClass:"buttonText actions"},[_vm._v("Login")]):_vm._e()]):_vm._e(),(_vm.isLogged)?_c('LoginHeader'):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }