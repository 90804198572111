<template>
  <div class="app-layout">
    <Navbar />
    <div style="min-height: 68vh; background-color:#f0f2f5;">
      <notifications :overlap="true"></notifications>
      <router-view
        style="background-color: #f0f2f5"
        :key="$route.fullPath"
      ></router-view>
    </div>
    <Footer style="background-color: #2a3550" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default { components: { Navbar, Footer }, methods: {}, mounted() {} };
</script>
<style scoped>
.app-layout {
  position: relative;

  background-color: #f0f2f5 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
}

.vue-notifyjs .alert {
  margin-top: 4rem !important;
}
</style>
