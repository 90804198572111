import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage
});

const modules = {};

export default function () {
  const Store = new Vuex.Store({
    namespace: true,
    plugins: [createPersistedState()],
    modules,
    plugins: [vuexLocalStorage.plugin],
    state: {
      data_trip: [],
      dadosPucharse: [],
      passageiroAdm: [],
      passageiros: [],
      valorTotal: {
        valor: 0
      },
      dadosViagem: {
        id: '',
        key: ''
      },
      session: {
        email: '',
        id: '',
        usuario: '',
        token: '',
        tokenCaptcha: '',
        auth: false
      },
      utils: {
        updated: false
      },
      log: {
        erro: false
      }
    },
    actions: {
      data_trip({ commit }, payload) {
        commit('DATA_TRIP', payload);
      },
      datas_purchase({ commit }, payload) {
        commit('DATA_PURCHASE', payload);
      },
      pop_purchase({ commit }, payload) {
        commit('POP_PURCHASE', payload);
      },
      pop_valorTotal({ commit }, payload) {
        commit('POP_VALORTOTAL', payload);
      },
      setValorTotal({ commit }, payload) {
        commit('SET_VALORTOTAL', payload);
      },
      pop_passenger({ commit }, payload) {
        commit('POP_PASSEGER', payload);
      },
      remove_passeger_all({ commit }, payload) {
        commit('REMOVE_PASSEGER_ALL');
      },
      add_passenger_adm({ commit }, payload) {
        commit('ADD_PASSEGER_ADM', payload);
      },
      add_passenger({ commit }, payload) {
        // console.log(payload);
        commit('ADD_PASSEGER', payload);
      },
      deleteValorTotal({ commit }) {
        commit('DELETE_VALOR');
      },
      updatedValorTotal({ commit }, payload) {
        commit('UPDATE_VALOR', payload);
      },
      viagemClear({ commit }) {
        commit('VIAGEM_CLEAR');
      },
      viagem({ commit }, payload) {
        commit('VIAGEM', payload);
      },
      update_login({ commit }, payload) {
        commit('UPDATE_LOGIN', payload);
      },
      login({ commit }, payload) {
        commit('LOGIN', payload);
      },
      logout({ commit }) {
        return new Promise(resolve => {
          commit('LOGOUT');
          resolve();
        });
      }
    },
    mutations: {
      DATA_TRIP(state, payload) {
        // console.log(payload);
        state.data_trip.push(payload);
      },
      DATA_PURCHASE(state, payload) {
        state.dadosPucharse.push({
          cep: payload.cep.replace('-', ''),
          rua: payload.rua,
          numero: payload.numero,
          bairro: payload.bairro,
          cidade: payload.cidade,
          estado: payload.estado
        });
      },
      POP_VALORTOTAL(state, payload) {
        state.valorTotal.valor -= payload;
      },

      SET_VALORTOTAL(state, payload) {
        state.valorTotal.valor = payload;
      },

      DELETE_VALOR(state) {
        state.valorTotal.valor = 0;
      },

      POP_PURCHASE(state, payload) {
        for (var i = 0; i < state.dadosPucharse.length; i++) {
          state.dadosPucharse.pop();
        }
      },
      REMOVE_PASSEGER_ALL(state) {
        state.passageiros = [];
      },

      POP_PASSEGER(state, payload) {
        if (payload != undefined) {
          for (var i = 0; i < state.passageiros.length; i++) {
            if (state.passageiros[i].cpf == payload.cpf) {
              state.passageiros.splice(i, 1);
            }
          }
        } else {
          for (var i = 0; i < state.passageiros.length; i++) {
            state.passageiros.pop();
          }
        }
      },
      ADD_PASSEGER_ADM(state, payload) {
        state.passageiroAdm = { ...payload };
      },
      ADD_PASSEGER(state, payload) {
        state.passageiros.push(payload);
      },
      UPDATE_VALOR(state, payload) {
        state.valorTotal.valor += payload.valor;
      },
      VALOR_CLEAR(state) {
        state.dadosViagem.id = '';
        state.dadosViagem.key = '';
      },
      VIAGEM_CLEAR(state) {
        state.dadosViagem.id = '';
        state.dadosViagem.key = '';
      },
      VIAGEM(state, payload) {
        state.dadosViagem.id = payload.id.toString();
        state.dadosViagem.key = payload.key;
      },
      UPDATE_LOGIN(state, payload) {
        state.session.token = payload.token;
      },
      LOGIN(state, payload) {
        state.session.id = payload.tokenCaptcha;
        state.session.id = payload.id;
        state.session.email = payload.email;
        state.session.usuario = payload.usuario;
        state.session.token = payload.token;
        state.session.auth = true;
      },
      LOGOUT(state, payload) {
        state.session.id = '';
        state.session.usuario = '';
        state.session.token = '';
        state.session.auth = false;
      }
    }
  });

  return Store;
}
