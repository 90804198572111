import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
import router from "./router/";
import store from "./store/index";
import GoogleLogin from "vue-google-login";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import axios from "axios";
import VueAxios from "vue-axios";
// import BlackDashboard from './plugins/blackDashboard';
// import i18n from './i18n';
import * as mdbvue from "mdbvue";
import VueMask from "v-mask";
import { VueMaskDirective } from "v-mask";
import VModal from "vue-js-modal";
import Autocomplete from "vuejs-auto-complete";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import VCalendar from "v-calendar";
import * as VueGoogleMaps from "vue2-google-maps";
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue/dist/bootstrap-vue'
import "./registerServiceWorker";
import "vue-multiselect/dist/vue-multiselect.min.css";
import ProgressBar from "vue-simple-progress";
import Spinner from "vue-simple-spinner";
import Notify from "vue-notifyjs";

import "@/assets/css/reset.css";
import "vue-notifyjs/themes/now-ui.css";

// import Vuetify from "vuetify";
// import "vuetify/dist/vuetify.min.css";

// import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Vue.use(BootstrapVue);
// Vue.use(BootstrapVueIcons);
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(Notify);

Vue.component("vue-simple-spinner", Spinner);

for (const component in mdbvue) {
  Vue.component(component, mdbvue[component]);
}
Vue.component("autocomplete", Autocomplete);
Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);
Vue.component("GoogleLogin", GoogleLogin);

// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
Vue.use(VModal, { dialog: true });
Vue.use(VueMask);
Vue.use(VCalendar, {
  locales: {
    "pt-PT": {
      firstDayOfWeek: 1,
      masks: {
        L: "YYYY-MM-DD"
      }
    }
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCIPo3kGcNp-vQBorcZY4iZINl1TUFmHrc",
    libraries: "places", // necessary for places input
    region: "br",
    language: "pt-br"
  }
});
// Vue.use(BlackDashboard);
// Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueAxios, axios);
Vue.use(VueMoment, {
  moment
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
