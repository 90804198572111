<template>
  <div>
    <div class="footer-dashboard">
      <div class="footer-dashboard-body">
        <div class="group">
          <div class="institucional">
            <p class="header">Institucional</p>
            <p style="cursor: pointer" @click="goTo('termosuso')">
              Termos de Uso
            </p>
            <p style="cursor: pointer" @click="goTo('sobrenos')">Sobre nós</p>
            <p style="cursor: pointer" @click="goTo('politicaprivacidade')">
              Política de privacidade
            </p>
          </div>
          <div class="Ajuda">
            <p class="header">Ajuda</p>
            <p style="cursor: pointer" @click="goTo('faq')">
              Dúvidas frequentes
            </p>
            <p style="cursor: pointer" @click="goTo('faleconosco')">
              Fale conosco
            </p>
            <p style="cursor: pointer">
              <a href="https://gruponavegam.com.br/" target="_blank"
                >Grupo NavegAM</a
              >
            </p>
          </div>
        </div>
        <div class="group">
          <div class="formas-pagamento">
            <p class="header">Formas de pagamento</p>
            <img :src="Brands" width="260px" class="brands" />
          </div>
          <div class="site-protegido">
            <p class="header">Siga-nos nas redes sociais!</p>
            <div>
              <a href="https://www.facebook.com/fepsnavegam" target="_blank">
                <img :src="IconFacebook" class="img-dash" />
              </a>
              <a href="https://www.instagram.com/navegam_/" target="_blank">
                <img
                  :src="IconInstagram"
                  class="img-dash"
                  style="cursor: pointer"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <img :src="navegamImg" />
        <p>
          © 2021 Navegam - Soluções em Mobilidade. Todos os direitos reservados.
        </p>
      </div>
    </div>
  </div>
  <!-- Copyright -->
</template>
<script>
import navegamImg from "@/assets/logo_navegam.png";
import logoImg from "@/assets/logo_navegam.png";
import IconFacebook from "@/assets/icons/icon-facebook.svg";
import IconInstagram from "@/assets/icons/icon-instagram.svg";
import Brands from "@/assets/icons/brands.svg";
export default {
  data() {
    return {
      IconFacebook,
      IconInstagram,
      Brands,
      navegamImg,
      logoImg,
      publicPath: process.env.VUE_APP_URL
    };
  },
  computed: {
    actualRoute: function() {
      return this.$route.path.substr(1);
    }
  },
  methods: {
    goTo(rota) {
      let routeData = this.$router.resolve({ name: rota });
      window.open(routeData.href, "_top");
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: white;
}
.header {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
}
.img-dash {
  width: 26px;
  float: none;
  margin-left: 0px;
}
.footer-dashboard {
  border-top: 5px solid #009aa0;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* padding-top: 2vh; */
  /* margin-top: 15px; */
}

.footer-dashboard-body {
  display: flex;
  justify-content: space-around;
  margin: 2rem;
}

.copyright {
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  img {
    margin-bottom: 0.5rem;
  }
}

.formas-pagamento {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  img {
    margin-left: 0;
  }
}
.brands {
  width: 150px;
}
p {
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 12px;
  margin: 0.5rem;
  margin-left: 0;
}

.footer-text {
  margin: 4px;
  color: #fafafa;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin-left: 18px;
}
label {
  padding-right: 8px;
}
img {
  float: right;
  margin: 0px 18px;
  width: 120px;
}

.group {
  display: flex;
  gap: 3rem;
}

@media only screen and (max-width: 425px) {
  .group {
    flex-direction: column;
    gap: 1rem;
  }
  *,
  p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 740px) {
}

@media only screen and (max-width: 900px) {
  /* .footer-dashboard {
    display: none;
  }
  .footer-colored {
    display: none;
  } */
}
</style>
