<template>
  <div class="entire">
    <div class="desc">
      <span class="user">{{ this.name }}</span>
      <!-- <span class="cargo">Usuário</span> -->
    </div>
    <div class="image">
      <img
        src="https://www.willierippleevents.com/wp-content/uploads/2016/01/Dummy-Profile-Picture-300x300.jpg"
      />
    </div>
    <div class="arrow dropdown">
      <img @click="toggleDropdown()" :src="IconArrowDown" class="dropbtn" />
      <div id="myDropdown" class="dropdown-content">
        <a href="/perfil">Meu Perfil</a>
        <a @click="logout()" href="/">Logout</a>
      </div>
    </div>
  </div>
</template>

<script>
// import { jwtHeader } from "@/utils/functions.js";
// import { custom_axios } from "@/utils/axios/custom-axios.js";

import IconArrowDown from "@/assets/icons/arrow-down.svg";

export default {
  name: "LoginHeader",
  mounted() {
    this.name = this.$store.state.session.usuario;
    // this.cargo = store().state.userInfo.infos.access_level_name;

    // document.addEventListener("click", this.close);
    window.onclick = function(event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  data() {
    return {
      IconArrowDown,
      name: "",
      show: false,
      paginaPesquisar: "search",
      paginaDashboard: "dashboard",
      paginaMapa: "mapa",
      paginaLogin: "login",
      paginaTickets: "meustickets",
      paginaCompra: "purchase",
      paginaCheckout: "checkout",
      paginaLogin: "login"
    };
  },
  methods: {
    toggleDropdown() {
      document.getElementById("myDropdown").classList.toggle("show");
    },
    rota(url) {
      this.$router.push(`/${url}`);
    },
    logout() {
      this.$store.dispatch("viagemClear");
      this.$store.dispatch("logout");
      this.$router.push("/");
      location.reload();
    }
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "OpenSans-Regular";
  src: url("~@/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
}

.entire {
  text-align: right;
  color: white;
  padding-right: 0.5rem;
  position: absolute;
  right: 5px;
  bottom: 0.8rem;
  // padding-top: 1rem;
  // margin-bottom: 3rem;
  // height: 120px;
}

.desc {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8rem;
  margin-top: 0.4rem;
  font-family: "OpenSans-Regular";

  .user {
    display: block;
    // font-weight: bold;
  }
  .cargo {
    display: block;
    margin-top: 0rem;
    text-transform: capitalize;
  }
}

.image {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
  margin-top: 0.5rem;

  img {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    border: 2px solid turquoise;
  }
}

.arrow {
  position: relative;
  float: right;
  vertical-align: center;
  margin-top: 1rem;
  margin-left: 0.2rem;
  img {
    height: 1rem;
    filter: invert(100%) sepia(0%) saturate(18%) hue-rotate(34deg)
      brightness(107%) contrast(99%);
    cursor: pointer;

    &:hover {
      filter: invert(79%) sepia(19%) saturate(5058%) hue-rotate(124deg)
        brightness(94%) contrast(98%);
    }
  }
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  // left: -6rem;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown {
  position: relative;
  display: inline-block;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: inline-block;
  right: 2px;
  top: 55px;
  text-align: left;

  // position: absolute;
  // top: 2rem;
  // z-index: 5;
}
</style>