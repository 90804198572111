<template>
  <div style="background-color: #f0f2f5">
    <div class="main-panel">
      <dashboard-content class="dashboard-margin"></dashboard-content>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import DashboardContent from "./Content.vue";
import Footer from "@/components/Footer.vue";
// window.$ = window.jQuery = require('jquery');

export default {
  components: {
    DashboardContent,
    Footer
  },
  methods: {
    formataHora(horario) {
      const h = horario.substring(0, 2);
      const m = horario.substring(3, 5);
      var hora = `${h}:${m}`;
      return hora;
    },
    show() {
      this.initialState();
      this.$modal.show("shipInformation");
    },
    formatData(data) {
      if (data) {
        const ano = data.substring(0, 4);
        const mes = data.substring(5, 7);
        const dia = data.substring(8, 10);

        data = `${dia}/${mes}/${ano}`;
      }
      return data;
    }
  }
};
</script>
<style lang="scss" scoped>
.main-panel {
  width: 100%;
  height: 100%;
  padding-bottom: 0vh;
  min-height: 86vh;
  background-color: #f0f2f5;
  background-size: 20rem;
}

.dashboard-margin {
  height: 100%;
  min-height: 36rem;
}
</style>
