import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";
// import store from '../store';

const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const UserProfile = () =>
  import(/* webpackChunkName: "common" */ "../pages/Profile/UserProfile.vue");
const Purchase = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Purchase.vue");
const SignUp = () =>
  import(/* webpackChunkName: "common" */ "@/pages/SignUp.vue");
const EsqueciSenha = () =>
  import(/* webpackChunkName: "common" */ "@/pages/EsqueciSenha.vue");
const ChangePassword = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile/ChangePassword.vue");
const Login = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Login.vue");
const MeusTickets = () =>
  import(/* webpackChunkName: "common" */ "@/pages/MeusTickets.vue");
const Mapa = () => import(/* webpackChunkName: "common" */ "@/pages/Mapa.vue");
const Checkout = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Checkout.vue");
const PoliticaPrivacidade = () =>
  import(/* webpackChunkName: "common" */ "@/pages/PoliticaPrivacidade.vue");
const SobreNos = () =>
  import(/* webpackChunkName: "common" */ "@/pages/SobreNos.vue");
const TermosUso = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TermosUso.vue");
const FAQ = () => import(/* webpackChunkName: "common" */ "@/pages/FAQ.vue");
const FaleConosco = () =>
  import(/* webpackChunkName: "common" */ "@/pages/FaleConosco.vue");

const routes = [
  // {
  //   path: '/search',
  //   name: 'Search',
  //   redirect: '/dashboard'
  // },
  // {
  //   path: '/mapa',
  //   name: 'Mapa',
  //   component: Mapa
  // },

  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/esquecisenha",
    name: "EsqueciSenha",
    component: EsqueciSenha
  },

  {
    path: "/purchase",
    name: "purchase",
    component: Purchase
  },
  {
    path: "/politicaprivacidade",
    name: "politicaprivacidade",
    component: PoliticaPrivacidade
  },
  {
    path: "/checkout",
    name: "checkout",
    component: Checkout
  },
  {
    path: "/meustickets",
    name: "MeusTickets",
    component: MeusTickets
  },
  {
    path: "/alterarsenha",
    name: "changepassword",
    component: ChangePassword
  },
  {
    path: "/sobrenos",
    name: "sobrenos",
    component: SobreNos
  },
  {
    path: "/termosuso",
    name: "termosuso",
    component: TermosUso
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ
  },
  {
    path: "/faleconosco",
    name: "faleconosco",
    component: FaleConosco
  },
  {
    path: "/perfil",
    name: "perfil",
    component: UserProfile
  },

  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "/",
        name: "dashboard",
        component: Dashboard
      }
    ]
  },
  { path: "*", component: NotFound }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
